/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */


/********************************************************
SCROLL FUNCTION
********************************************************/
jQuery(window).scroll(function() {   
  /* ClASS AFTER SCROLL */
  var scroll = jQuery(window).scrollTop();
  if (scroll >= 200) {
    jQuery("#header").addClass("fixed");
    jQuery("body").addClass("fixed");
  } else {
    jQuery("#header").removeClass("fixed");
    jQuery("body").removeClass("fixed");
  }
});

/* HEIGHT OF PARENT PART 1 */
var elementHeights = jQuery('.text').map(function() {
  return jQuery(this).height();
}).get();
var maxHeight = Math.max.apply(null, elementHeights);
jQuery('.text').height(maxHeight);

/* HEIGHT OF PARENT PART 3 */
resizeContent();
jQuery(window).resize(function() {
  resizeContent();
});

/********************************************************
RESIZE CONTENT
********************************************************/
function resizeContent() {
  jQueryheight = jQuery('.text').parent().height();
  jQuery('.height').height(jQueryheight);

  jQueryheight2 = jQuery('.text2').parent().height();
  jQuery('.height2').height(jQueryheight2);
}

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        jQuery('#my-slider').unslider({
          animation: 'fade',
          autoplay: true,
          arrows: true,
          nav: true,
          delay: 10000
        });

        // JavaScript to be fired on all pages
        jQuery('#my-slider-bu').unslider({
          animation: 'fade',
          autoplay: false,
          arrows: false,
          nav: false,
          delay: 10000
        });

        jQuery('.owl-carousel').owlCarousel( {
          loop:true,
          nav:true,
          pager:true,
          responsive:{
              0:{
                  items:1
              },
              600:{
                  items:1
              },
              1000:{
                  items:1
              }
          }
        });

        /* CLOSE MENU */
        jQuery('.fa-bars-menu').click(function(){
          jQuery('#mobile-menu').addClass('clicked');
          jQuery('#overlay').addClass('clicked');
        });
        jQuery('.fa-close-menu, #overlay').click(function(){
          jQuery('#mobile-menu').removeClass('clicked');
          jQuery('#overlay').removeClass('clicked');
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
